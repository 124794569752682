<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpy-2">
        <span class="cpx-4 font-title">デザイン設定 </span>
      </div>
      <div class="px-4 col col-auto">
        <div class="mx-3">
          <label
            v-for="(item, index) in listSetting"
            :key="index"
            class="mr-3 position-relative py-2"
            :class="
              typeSetting === item.value
                ? 'btn-tab-sale-active'
                : 'btn-tab-sale-inactive'
            "
            @click="typeSetting = item.value"
            style="font-weight: normal !important; font-size: 17px"
          >
            {{ item.text }}
          </label>
        </div>
      </div>
      <form class="cpx-15 pb-2" id="create-content" autocomplete="off">
        <div v-if="typeSetting == 1">
          <div class="row row-input mb-3">
            <label class="col-sm-12 font-weight-bold cp-label"
              >トップページ
            </label>
          </div>
          <div class="row mb-3 row-input align-items-center">
            <div class="col-sm-2 text-lg-left px-0 my-auto">
              <label class="cp-label m-0" for="post_banner-image-button">
                ヘッダー背景
              </label>
            </div>
            <div class="custome-input-one-column">
              <v-input-colorpicker
                v-model="header_background"
                class="header-background color-field position-absolute"
                style="z-index: -1"
              />
              <input
                class="input color-field position-relative"
                style="width: 45%; margin-left: 0px"
                type="text"
                v-model="header_background"
                @click="changeColor('header-background')"
                :style="{ background: header_background }"
              />
              <span> 色を選択</span>
            </div>
          </div>
          <div class="row mb-3 row-input align-items-center">
            <div class="col-sm-2 text-lg-left px-0 my-auto">
              <label class="cp-label m-0" for="post_banner-image-button">
                メニュー背景
              </label>
            </div>
            <div class="custome-input-one-column">
              <v-input-colorpicker
                v-model="menu_background"
                class="menu-background color-field position-absolute"
                style="z-index: -1"
              />
              <input
                class="input color-field position-relative"
                style="width: 45%; margin-left: 0px"
                type="text"
                v-model="menu_background"
                @click="changeColor('menu-background')"
                :style="{ background: menu_background }"
              />
              <span> 色を選択</span>
            </div>
          </div>
          <div class="row mb-3 row-input align-items-center">
            <div class="col-sm-2 text-lg-left px-0 my-auto">
              <label class="cp-label m-0" for="post_banner-image-button">
                ボディ背景
              </label>
            </div>
            <div class="custome-input-one-column">
              <v-input-colorpicker
                v-model="body_background"
                class="body-background color-field position-absolute"
                style="z-index: -1"
              />
              <input
                class="input color-field position-relative"
                style="width: 45%; margin-left: 0px"
                type="text"
                v-model="body_background"
                @click="changeColor('body-background')"
                :style="{ background: body_background }"
              />
              <span> 色を選択</span>
            </div>
          </div>
        </div>
        <div v-else-if="typeSetting == 2">
          <div class="row row-input mb-3">
            <label class="col-sm-12 font-weight-bold cp-label"
              >目次ページ
            </label>
          </div>
          <div class="row mb-3 row-input align-items-center">
            <div class="col-sm-2 text-lg-left px-0 my-auto">
              <label class="cp-label m-0" for="post_banner-image-button">
                ボディ背景
              </label>
            </div>
            <div class="custome-input-one-column">
              <v-input-colorpicker
                v-model="table_content_background"
                class="table-content-page color-field position-absolute"
                style="z-index: -1"
              />
              <input
                class="input color-field position-relative"
                style="width: 45%; margin-left: 0px"
                type="text"
                v-model="table_content_background"
                @click="changeColor('table-content-page')"
                :style="{ background: table_content_background }"
              />
              <span> 色を選択</span>
            </div>
          </div>
          <div class="row row-input mb-3">
            <label class="col-sm-12 font-weight-bold cp-label"
              >コンテンツページ
            </label>
          </div>
          <div class="row mb-3 row-input align-items-center">
            <div class="col-sm-2 text-lg-left px-0 my-auto">
              <label class="cp-label m-0" for="post_banner-image-button">
                ボディ背景
              </label>
            </div>
            <div class="custome-input-one-column">
              <v-input-colorpicker
                v-model="content_background"
                class="content-page color-field position-absolute"
                style="z-index: -1"
              />
              <input
                class="input color-field position-relative"
                style="width: 45%; margin-left: 0px"
                type="text"
                v-model="content_background"
                @click="changeColor('content-page')"
                :style="{ background: content_background }"
              />
              <span> 色を選択</span>
            </div>
          </div>
        </div>
        <div v-else-if="typeSetting == 3">
          <div class="row row-input mb-3">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ショップページ
            </label>
          </div>
          <div class="row mb-3 row-input align-items-center">
            <div class="col-sm-2 text-lg-left px-0 my-auto">
              <label class="cp-label m-0" for="post_banner-image-button">
                ボディ背景
              </label>
            </div>
            <div class="custome-input-one-column">
              <v-input-colorpicker
                v-model="shop_background"
                class="shop-page color-field position-absolute"
                style="z-index: -1"
              />
              <input
                class="input color-field position-relative"
                style="width: 45%; margin-left: 0px"
                type="text"
                v-model="shop_background"
                @click="changeColor('shop-page')"
                :style="{ background: shop_background }"
              />
              <span> 色を選択</span>
            </div>
          </div>
          <div class="row row-input mb-3">
            <label class="col-sm-12 font-weight-bold cp-label"
              >コンテンツ詳細ページ
            </label>
          </div>
          <div class="row mb-3 row-input align-items-center">
            <div class="col-sm-2 text-lg-left px-0 my-auto">
              <label class="cp-label m-0" for="post_banner-image-button">
                ポディ背景
              </label>
            </div>
            <div class="custome-input-one-column">
              <v-input-colorpicker
                v-model="content_detail_background"
                class="content-detail-page color-field position-absolute"
                style="z-index: -1"
              />
              <input
                class="input color-field position-relative"
                style="width: 45%; margin-left: 0px"
                type="text"
                v-model="content_detail_background"
                @click="changeColor('content-detail-page')"
                :style="{ background: content_detail_background }"
              />
              <span> 色を選択</span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-4">
          <button
            :disabled="isLoading"
            variant="success"
            class="mr-4 btn-save"
            @click="saveDesignSetting()"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            更新する
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
import InputColorPicker from "vue-native-color-picker";
export default {
  name: "general_setting",
  components: {
    "v-input-colorpicker": InputColorPicker,
  },
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      typeSetting: 1,
      listSetting: [
        { value: 1, text: "全体" },
        { value: 2, text: "コンテンツページ" },
        { value: 3, text: "ショップページ" },
      ],
      header_background: "#ffffff",
      menu_background: "#ffffff",
      body_background: "#ffffff",
      table_content_background: "#ffffff",
      content_background: "#ffffff",
      shop_background: "#ffffff",
      content_detail_background: "#ffffff",
      isLoading: false,
    };
  },
  created() {
    const request = {
      shop_id: this.shop_id,
    };
    this.showDesignSetting(request);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    detailDesignSetting() {
      this.header_background = this.detailDesignSetting.header_background;
      this.menu_background = this.detailDesignSetting.menu_background;
      this.body_background = this.detailDesignSetting.body_background;
      this.table_content_background =
        this.detailDesignSetting.table_content_background;
      this.content_background = this.detailDesignSetting.content_background;
      this.shop_background = this.detailDesignSetting.shop_background;
      this.content_detail_background =
        this.detailDesignSetting.content_detail_background;
    },
  },
  computed: {
    ...mapGetters(["success", "message", "error", "detailDesignSetting"]),
  },
  methods: {
    ...mapActions({
      showDesignSetting: "showDesignSetting",
      updateDesignSetting: "updateDesignSetting",
    }),
    changeColor(name) {
      document.getElementsByClassName(name)[0].click();
    },
    async saveDesignSetting() {
      this.isLoading = true;
      const formData = {
        id: this.shop_id,
        header_background: this.header_background,
        menu_background: this.menu_background,
        body_background: this.body_background,
        table_content_background: this.table_content_background,
        content_background: this.content_background,
        shop_background: this.shop_background,
        content_detail_background: this.content_detail_background,
      };
      await this.updateDesignSetting(formData);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.fontStyle {
  font-size: 14px;
}
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.custom-text cp-label {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.row-input input {
  margin: 0px !important;
}

.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  border-radius: 3px;
  width: 200px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 200px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
</style>
